<template>
  <el-popover placement="right" trigger="click">
    <p v-if="content" class="label-describe">
      {{ content }}
    </p>
    <div v-if="Array.isArray(images) && images.length" class="label-images">
      <el-image
        v-for="(item, index) in images"
        :key="`images-${index}`"
        :src="item.validUrl"
        :alt="item.attachmentName"
        fit="cover"
        :preview-src-list="generatePreviewList(images, index)"
      >
      </el-image>
    </div>
    <el-button slot="reference" type="text" icon="el-icon-question"></el-button>
  </el-popover>
</template>

<script>
export default {
  name: 'LabelDescribe',
  components: {},
  props: {
    content: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    generatePreviewList(images, index) {
      const list = [];
      const { length } = images;
      for (let i = 0; i < length; i += 1) {
        list.push(images[i + index].validUrl);
        if (i + index >= length - 1) {
          index = 0 - (i + 1);
        }
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-popover__reference-wrapper {
  .el-button {
    margin-left: 5px;
    color: #e6a23c;
    font-size: 16px;
  }
  .el-icon-question {
    margin-left: 5px;
    color: #e6a23c;
    cursor: pointer;
  }
}
.label-describe {
  color: #e6a23c;
}
.label-images {
  margin-top: 15px;
  .el-image {
    margin-right: 15px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
