const mixin = {
  methods: {
    _updateFilefield(attachmentType, files) {
      const names = files.map(val => val.name);
      const target = { ...this.model };
      Object.assign(target, {
        [`attachment-${attachmentType}`]: `${names}`,
      });
      this.model = target;

      this.mapOfAttachment[attachmentType] = files.map(({ name }) => {
        const arr = name.split('/');
        const filename = arr[arr.length - 1];
        const item = {
          attachmentName: filename,
          attachmentPath: name,
          attachmentType,
        };
        return item;
      });
    },
  },
};

export default mixin;
