import { postInfo } from './api';

export default {
  getFirstQuestionByProductId(data) {
    return postInfo('/assessment/getFirstQuestionByProductId', data);
  },
  getNextQuestion(data) {
    return postInfo('/assessment/getNextQuestion', data);
  },
  submitAssessment(data) {
    return postInfo('/assessment/submitAssessment', data);
  },
};
