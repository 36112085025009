import { postInfo } from './api';

export default {
  getOrderInfoList(data) {
    return postInfo('/order/getOrderInfoList', data);
  },
  deleteOrderInfo(data) {
    return postInfo('/order/deleteOrderInfo', data);
  },
  // 申请赎回
  applyRedeem(data) {
    return postInfo('/order/applyRedeem', data);
  },
  getOrderInfo(data) {
    return postInfo('/order/getOrderInfo', data);
  },
  addOrderInfo(data) {
    return postInfo('/order/addOrderInfo', data);
  },
  getOrderTimeline(data) {
    return postInfo('/order/getOrderTimeline', data);
  },
  // 查看机型配置
  getProductOptionList(data) {
    return postInfo('/product/getProductOptionList', data);
  },
  // 待收货订单列表
  getReceivingOrderList(data) {
    return postInfo('/order/getReceivingOrderList', data);
  },
  // 待检测订单列表
  getDetectionOrderList(data) {
    return postInfo('/order/getDetectionOrderList', data);
  },
  // 待确认估值订单列表
  getConfirmAssessmentOrderList(data) {
    return postInfo('/order/getConfirmAssessmentOrderList', data);
  },
  // 待签约订单列表
  getSignOrderList(data) {
    return postInfo('/order/getSignOrderList', data);
  },
  // 待回寄订单列表（取消）
  getReturnOrderList(data) {
    return postInfo('/order/getReturnOrderList', data);
  },
  // 待确认到货订单列表（取消）
  getArrivalOrderList(data) {
    return postInfo('/order/getArrivalOrderList', data);
  },
  // 已到货订单列表（取消）
  getConfirmArrivalOrderList(data) {
    return postInfo('/order/getConfirmArrivalOrderList', data);
  },
  // 已签约待支付订单列表
  getWaitPayOrderList(data) {
    return postInfo('/order/getWaitPayOrderList', data);
  },
  // 已支付订单列表
  getPayOrderList(data) {
    return postInfo('/order/getPayOrderList', data);
  },
  // 寄存期满待赎回确认订单列表
  getConfirmRedeemOrderList(data) {
    return postInfo('/order/getConfirmRedeemOrderList', data);
  },
  // 已赎回待回寄订单列表
  getRedeemReturnOrderList(data) {
    return postInfo('/order/getRedeemReturnOrderList', data);
  },
  // 待确认到货订单列表（赎回）
  getRedeemArrivalOrderList(data) {
    return postInfo('/order/getRedeemArrivalOrderList', data);
  },
  // 已确认到货订单列表（赎回）
  getRedeemConfirmArrivalOrderList(data) {
    return postInfo('/order/getRedeemConfirmArrivalOrderList', data);
  },
  // 确认延期待签约付定金订单列表
  getWaitRenewalOrderList(data) {
    return postInfo('/order/getWaitRenewalOrderList', data);
  },
  // 已延期生效订单列表
  getRenewalOrderList(data) {
    return postInfo('/order/getRenewalOrderList', data);
  },
  // 放弃赎回待签约订单列表
  getWaiveOrderList(data) {
    return postInfo('/order/getWaiveOrderList', data);
  },
  // 已签约放弃物权订单列表
  getSignWaiveOrderList(data) {
    return postInfo('/order/getSignWaiveOrderList', data);
  },
  // 未签约放弃物权订单列表
  getNoSignWaiveOrderList(data) {
    return postInfo('/order/getNoSignWaiveOrderList', data);
  },

  // 待收货，受理
  acceptReceivingOrder(data) {
    return postInfo('/order/acceptReceivingOrder', data);
  },
  // 已收货待检测,开始检测
  beginDetectionOrder(data) {
    return postInfo('/order/beginDetectionOrder', data);
  },
  // 已收货待检测,结束检测
  finishDetectionOrder(data) {
    return postInfo('/order/finishDetectionOrder', data);
  },
  // 已检测待确认估值，开始确认
  beginConfirmOrder(data) {
    return postInfo('/order/beginConfirmOrder', data);
  },
  // 已检测待确认估值，结束确认
  finishConfirmOrder(data) {
    return postInfo('/order/finishConfirmOrder', data);
  },
  // 已确认估值待签约，开始签约
  beginSign(data) {
    return postInfo('/order/beginSign', data);
  },
  // 已确认估值待签约，结束签约
  finishSign(data) {
    return postInfo('/order/finishSign', data);
  },
  // 已取消质押待回寄，受理
  acceptReturn(data) {
    return postInfo('/order/acceptReturn', data);
  },
  // 已回寄待确认到货（取消质押），受理
  acceptArrival(data) {
    return postInfo('/order/acceptArrival', data);
  },
  // 已签约待支付[受理]
  acceptPayment(data) {
    return postInfo('/order/acceptPayment', data);
  },
  // 期满待赎回确认[开始确认orderId]
  beginConfirmRedeem(data) {
    return postInfo('/order/beginConfirmRedeem', data);
  },
  // 期满待赎回确认[结束确认]
  finishConfirmRedeem(data) {
    return postInfo('/order/finishConfirmRedeem', data);
  },
  // 库存列表
  getWarehouseList(data) {
    return postInfo('/storage/warehouse/list', data);
  },
  // 入库操作
  putStorage(data) {
    return postInfo('/order/putStorage', data);
  },
  // 已赎回待回寄[受理]
  acceptRedeemReturn(data) {
    return postInfo('/order/acceptRedeemReturn', data);
  },
  // 确认延期待签约付定金,开始收款签约
  beginRenewalSign(data) {
    return postInfo('/order/beginRenewalSign', data);
  },
  // 确认延期待签约付定金,结束收款签约
  finishRenewalSign(data) {
    return postInfo('/order/finishRenewalSign', data);
  },
  // 放弃赎回待签约，开始签约
  beginSignWaive(data) {
    return postInfo('/order/beginSignWaive', data);
  },
  // 放弃赎回待签约，结束签约
  finishSignWaive(data) {
    return postInfo('/order/finishSignWaive', data);
  },

  // 已回寄待确认到货（赎回），受理
  acceptRedeemArrival(data) {
    return postInfo('/order/acceptRedeemArrival', data);
  },

  // 订单保存信息
  getOrderSaveInfo(data) {
    return postInfo('/order/getOrderSaveInfo', data);
  },

  // 已签约|未签约放弃物权[物品处置]
  goodsDisposal(data) {
    return postInfo('/order/goodsDisposal', data);
  },
  // 合作商交易数据统计
  getSum(data) {
    return postInfo('/order/partnerOrderListSum', data);
  },
};
