<template>
  <el-dialog
    custom-class=""
    title="评估"
    :visible.sync="visible"
    append-to-body
    destroy-on-close
    @opened="getFirst"
    @closed="closed"
  >
    <el-form
      ref="form"
      size="small"
      label-width="100px"
      :model="model"
      label-position="top"
    >
      <template v-for="(item, index) in formItems">
        <el-form-item
          v-if="item.questionId !== null"
          :key="`formItems-${index}`"
          :prop="`form-item-${index}`"
          :rules="generateRules(item)"
        >
          <div class="label-slot" slot="label">
            {{ index + 1 }}、{{ item.questionDescription }}
            <label-describe
              v-if="
                item.questionExplain ||
                (item.attachmentList && item.attachmentList.length)
              "
              :content="item.questionExplain"
              :images="item.attachmentList"
            ></label-describe>
          </div>
          <template v-if="item.questionType === 1">
            <el-radio-group v-model="model[`form-item-${index}`]">
              <el-radio
                v-for="(option, j) in item.optionList"
                :key="`formItems-${index}-option-${j}`"
                :label="option.optionId"
                @change="change(item, index)"
              >
                {{ option.optionDescription }}
                <label-describe
                  v-if="
                    option.optionExplain ||
                    (option.attachmentList && option.attachmentList.length)
                  "
                  :content="option.optionExplain"
                  :images="option.attachmentList"
                ></label-describe>
              </el-radio>
            </el-radio-group>
          </template>
          <template v-else-if="item.questionType === 2">
            <el-checkbox-group v-model="model[`form-item-${index}`]">
              <el-checkbox
                v-for="(option, j) in item.optionList"
                :key="`formItems-${index}-option-${j}`"
                :label="option.optionId"
                @change="change(item, index)"
              >
                {{ option.optionDescription }}
                <label-describe
                  v-if="
                    option.optionExplain ||
                    (option.attachmentList && option.attachmentList.length)
                  "
                  :content="option.optionExplain"
                  :images="option.attachmentList"
                ></label-describe>
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
      </template>
      <div class="loading" v-loading="loading"></div>
    </el-form>

    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button
        v-if="
          formItems.length &&
          (formItems[formItems.length - 1].redirectType === 2 ||
            formItems[formItems.length - 1].redirectType === 3 ||
            formItems[formItems.length - 1].isMustAnswer === 0)
        "
        type="primary"
        :loading="submitLoading"
        @click="confirm"
      >
        提交估价
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '../../../api/Assessment';
import oss from '../../../utils/oss';
import LabelDescribe from '../../../components/LabelDescribe.vue';
import { mapState } from 'vuex';

const client = oss.client();

export default {
  name: 'Assessment',
  components: {
    LabelDescribe,
  },
  props: {},
  data() {
    return {
      visible: false,
      productId: '',
      loading: false,
      model: {},
      formItems: [],
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
  },
  created() {
    // console.log(this.model);
  },
  methods: {
    open(productId) {
      this.productId = productId;
      this.loading = true;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    closed() {
      this.productId = '';
      this.model = {};
      this.loading = false;
      this.formItems = [];
      this.submitLoading = false;
    },
    getFirst() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { productId } = this;
        api
          .getFirstQuestionByProductId(productId)
          .then(res => {
            this.addFormItems(res.result);
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    getNext() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, formItems } = this;
        const allOptionList = Object.values(model).flat();

        const lastField = `form-item-${formItems.length - 1}`;
        const lastValue = model[lastField];
        const currentOptionList = Array.isArray(lastValue)
          ? lastValue
          : [lastValue];

        const ext = {
          allOptionList,
          currentOptionList,
        };
        api
          .getNextQuestion(ext)
          .then(res => {
            this.addFormItems(res.result);
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    addFormItems(item) {
      if (!item) {
        return false;
      }
      this.generateValidUrl(item);
      if (item.questionType === 2) {
        const target = { ...this.model };
        const field = `form-item-${this.formItems.length}`;
        target[field] = [];
        this.model = target;
      }
      this.formItems.push(item);
      return true;
    },
    formatAttachmentList(attachmentList) {
      if (attachmentList && Array.isArray(attachmentList)) {
        attachmentList.forEach(point => {
          const { attachmentPath } = point;
          if (attachmentPath) {
            point.validUrl = client.signatureUrl(attachmentPath);
          }
        });
      }
    },
    generateValidUrl(target) {
      this.formatAttachmentList(target.attachmentList);
      if (target.optionList && Array.isArray(target.optionList)) {
        target.optionList.forEach(option => {
          this.formatAttachmentList(option.attachmentList);
        });
      }
    },

    generateRules(item) {
      const {
        isMustAnswer,
        questionType,
        choiceRangeMin,
        choiceRangeMax,
      } = item;
      const rules = [];
      if (isMustAnswer === 1) {
        const rule = {
          required: true,
          message: '该题不能为空',
        };
        rules.push(rule);
      }
      if (questionType === 2) {
        const rule = {
          type: 'array',
          trigger: 'change',
          min: choiceRangeMin,
          max: choiceRangeMax,
          message: `该题可以选择${choiceRangeMin}-${choiceRangeMax}项`,
        };
        rules.push(rule);
      }

      return rules;
    },
    change(formItem, index) {
      const { formItems } = this;

      if (index !== formItems.length - 1) {
        const delIndex = index + 1;
        const delCount = formItems.length - delIndex;
        formItems.splice(delIndex, delCount);
        const target = { ...this.model };
        for (let j = 0; j < delCount; j += 1) {
          const field = `form-item-${delIndex + j}`;
          Reflect.deleteProperty(target, field);
        }
        this.model = target;
      }

      const { loading } = this;
      if (loading) {
        return false;
      }

      const { redirectType } = formItem;
      if (redirectType && redirectType !== 1) {
        return false;
      }

      const field = `form-item-${index}`;
      const value = this.model[field];

      const { questionType, choiceRangeMin, choiceRangeMax } = formItem;
      if (
        questionType === 2 &&
        (value.length < choiceRangeMin || value.length > choiceRangeMax)
      ) {
        return false;
      }
      return this.getNext();
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitAssessment();
        }
      });
    },
    submitAssessment() {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { productId, model, formItems } = this;
        const submitAnswerList = formItems
          .filter(({ questionId }) => questionId !== null)
          .map(({ questionId }, index) => {
            const field = `form-item-${index}`;
            const value = model[field];
            const optionIdList = Array.isArray(value) ? value : [value];
            return {
              questionId,
              optionIdList,
            };
          })
          .filter(({ optionIdList }) => optionIdList.length);

        const ext = {
          assessmentType: 2,
          productId,
          productName: '',
          submitAnswerList,
          userId: this.userInfo.userId,
        };
        api
          .submitAssessment(ext)
          .then(res => {
            this.$emit('submit', res.result);
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  margin-top: 40px;
}

.label-slot {
  display: inline-block;
}
</style>
